import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Check, Receipt } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class Export2316 extends Component {
  constructor(props) {
    super();
    this.state = {
      showDeleteModal: false,
      disableSubmit: false,
      status: 2,
    };
    this.handleshowDeleteModal = this.handleshowDeleteModal.bind(this);
  }

  handleshowDeleteModal = (e) => {
    let checked = this.props.dataCheck;
    let checkfiltered = this.props.data.filter(function (data) {
      let checkValue = false;
      checked.map((checkData) => {
        if (checkData["checked"])
          if (data["year_id"] == checkData["id"]) {
            checkValue = true;
          }
      });

      return checkValue;
    });

    if (checkfiltered.length > 0) {
      this.setState({ showDeleteModal: !this.state.showDeleteModal });
    } else {
      alert("No Data Selected");
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    let checked = this.props.dataCheck;
    let checkfiltered = this.props.data.filter(function (data) {
      let checkValue = false;
      checked.map((checkData) => {
        if (checkData["checked"])
          if (data["year_id"] == checkData["id"]) {
            checkValue = true;
          }
      });

      return checkValue;
    });

    if (checkfiltered.length > 0) {
      axios({
        url:
          process.env.REACT_APP_API_LINK +
          "api/export_2316/" +
          cookies.get("transCompany"), //your url
        method: "POST",
        data: [checkfiltered, this.state.deadline, this.state.signatory],
        responseType: "blob", // important
      }).then((res) => {
        // const url = window.URL.createObjectURL(new Blob([res.data]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download","YTD-Employee-Details.xlsx"); //or any other extension
        // document.body.appendChild(link);
        // link.click();
        this.setState({ showDeleteModal: false, disableSubmit: false });
        window.location.reload(false);
      });
    } else {
      alert("No Data Selected");
    }
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm float-right mr-2"
          title="Export 2316"
          onClick={this.handleshowDeleteModal}
        >
          <Receipt /> Export 2316 Form
        </button>
        <Modal
          size="md"
          backdrop="static"
          show={this.state.showDeleteModal}
          onHide={() =>
            this.setState({ showDeleteModal: !this.state.showDeleteModal })
          }
        >
          <Modal.Header>
            <h4>
              Send 2316 form email to selected employee(s)
              <br />
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Deadline</label>
                  <input
                    class="form-control"
                    type="date"
                    required
                    onChange={(e) =>
                      this.setState({ deadline: e.target.value })
                    }
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Signatory(Full Name)</label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="First Name M. Last Name"
                    required
                    onChange={(e) =>
                      this.setState({ signatory: e.target.value })
                    }
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Are you sure?</label>
                </div>
              </div>
              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Send Email{" "}
                  {this.state.disableSubmit ? (
                    <span
                      class="spinner spinner-border spinner-border-sm"
                      id="spinner"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    ""
                  )}
                </button>
                <button
                  onClick={this.handleshowDeleteModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                  disabled={this.state.disableSubmit}
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const empStatus = ["INACTIVE", "ACTIVE"];

export default class EditEmployee extends Component {
  constructor(props) {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      company_id: cookies.get("transCompany"),
      showEditModal: false,
      hasError: false,
      disableSubmit: false,
    };

    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({
      emp_no: this.props.empDetail.emp_no,
      company_id: this.props.empDetail.company_id,
    });
    this.setState({
      showEditModal: !this.state.showEditModal,
      hasError: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios
      .put("api/employee/" + this.props.empDetail.id, this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({
          showEditModal: false,
          hasError: false,
          disableSubmit: false,
        });
      })
      .catch((err) => {
        this.setState({ disableSubmit: false, hasError: true });
        console.log(err);
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm"
          title="Edit Employee Details"
          onClick={this.handleshowEditModal}
        >
          <Edit />
        </button>
        <Modal
          size="xl"
          backdrop="static"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Edit Employee Details</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Employee number already existing.
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-4 col-sm-12">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ first_name: e.target.value })
                    }
                    defaultValue={this.props.empDetail.first_name}
                  />
                </div>
                <div className="form-group col-md-4 col-sm-12">
                  <label>Middle Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ mid_name: e.target.value })
                    }
                    defaultValue={this.props.empDetail.mid_name}
                  />
                </div>
                <div className="form-group col-md-4 col-sm-12">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ last_name: e.target.value })
                    }
                    defaultValue={this.props.empDetail.last_name}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Employee #</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ emp_no: e.target.value })}
                    defaultValue={this.props.empDetail.emp_no}
                  />
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Date of Birth</label>
                  <input
                    class="form-control"
                    type="date"
                    onChange={(e) =>
                      this.setState({ date_of_birth: e.target.value })
                    }
                    defaultValue={this.props.empDetail.date_of_birth}
                  />
                </div>
              </div>

              <div className="row">
              <div className="form-group col-md-6 col-sm-12">
                  <label>Contact #</label>
                  <input
                    type="tel"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ contact_number: e.target.value })
                    }
                    defaultValue={this.props.empDetail.contact_number}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>Company Email</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ company_email: e.target.value })
                    }
                    defaultValue={this.props.empDetail.company_email}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Department</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ department: e.target.value })
                    }
                    defaultValue={this.props.empDetail.department}
                  />
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Position</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ position: e.target.value })
                    }
                    defaultValue={this.props.empDetail.position}
                  />
                </div>
              </div>

              <div className="row">
                
                <div className="form-group col-md-6 col-sm-12">
                  <label>Status</label>
                  <select
                    class="form-control custom-select"
                    onChange={(e) =>
                      this.setState({ emp_status: e.target.value })
                    }
                  >
                    <option
                      value={1}
                      selected={
                        this.props.empDetail.emp_status == 1 ? "selected" : ""
                      }
                    >
                      {empStatus[1]}
                    </option>
                    <option
                      value={0}
                      selected={
                        this.props.empDetail.emp_status == 0 ? "selected" : ""
                      }
                    >
                      {empStatus[0]}
                    </option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>SSS #</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    defaultValue={this.props.empDetail.sss_no}
                    onChange={(e) => this.setState({ sss_no: e.target.value })}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>HMDF #</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    defaultValue={this.props.empDetail.hmdf_no}
                    onChange={(e) => this.setState({ hmdf_no: e.target.value })}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>PhilHealth #</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    defaultValue={this.props.empDetail.philhealth_no}
                    onChange={(e) =>
                      this.setState({ philhealth_no: e.target.value })
                    }
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>TIN #</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    maxLength={14}
                    defaultValue={this.props.empDetail.tin_no}
                    onChange={(e) =>
                      this.setState({ tin_no: e.target.value })
                    }
                  />
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Bank</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({ bank_id: e.target.value })
                    }
                    defaultValue={this.props.empDetail.bank_id}
                  >
                    <option value="">Select Bank</option>
                    {this.props.bankDD.map((bank) => (
                      <option key={bank.id} value={bank.id}>
                         {bank.name} ({bank.code})
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Bank Account #</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    defaultValue={this.props.empDetail.bank_account_no}
                    onChange={(e) =>
                      this.setState({ bank_account_no: e.target.value })
                    }
                  />
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Hire Date</label>
                  <input
                    class="form-control"
                    type="date"
                    defaultValue={this.props.empDetail.date_hired}
                    onChange={(e) =>
                      this.setState({ date_hired: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

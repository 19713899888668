import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Add } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const empStatus = ["INACTIVE", "ACTIVE"];

export default class AddEmployee extends Component {
  constructor(props) {
    super();
    this.state = {
      showAddModal: false,
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      company_id: cookies.get("transCompany"),
      hasError: false,
      disableSubmit: false,
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({ showAddModal: !this.state.showAddModal, hasError: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios
      .post("api/employee", this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({
          showAddModal: false,
          hasError: false,
          disableSubmit: false,
        });
      })
      .catch((err) => {
        this.setState({ disableSubmit: false, hasError: true });
        console.log(err);
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm float-right"
          title="Add New Employee"
          onClick={this.handleshowAddModal}
        >
          <Add /> New Employee
        </button>
        <Modal
          size="xl"
          backdrop="static"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Add New Employee</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Employee number already existing.
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-4 col-sm-12">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ first_name: e.target.value })
                    }
                  />
                </div>
                <div className="form-group col-md-4 col-sm-12">
                  <label>Middle Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ mid_name: e.target.value })
                    }
                  />
                </div>
                <div className="form-group col-md-4 col-sm-12">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ last_name: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Employee #</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ emp_no: e.target.value })}
                  />
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Date of Birth</label>
                  <input
                    class="form-control"
                    required
                    type="date"
                    onChange={(e) =>
                      this.setState({ date_of_birth: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Contact #</label>
                  <input
                    type="tel"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ contact_number: e.target.value })
                    }
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>Company Email</label>
                  <input
                    type="email"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ company_email: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Department</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ department: e.target.value })
                    }
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>Position</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ position: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Status</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({ emp_status: e.target.value })
                    }
                  >
                    <option value="">Select Status</option>
                    <option value={1}>{empStatus[1]}</option>
                    <option value={0}>{empStatus[0]}</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>SSS #</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ sss_no: e.target.value })}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>HMDF #</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ hmdf_no: e.target.value })}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>PhilHealth #</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ philhealth_no: e.target.value })
                    }
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>TIN #</label>
                  <input
                    type="text"
                    className="form-control"
                    maxLength={14}
                    required
                    onChange={(e) => this.setState({ tin_no: e.target.value })}
                  />
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Bank</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) => this.setState({ bank_id: e.target.value })}
                  >
                    <option value="">Select Bank</option>
                    {this.props.bankDD.map((bank) => (
                      <option key={bank.id} value={bank.id}>
                        {bank.name} ({bank.code})
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Bank Account #</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ bank_account_no: e.target.value })
                    }
                  />
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Hire Date</label>
                  <input
                    class="form-control"
                    type="date"
                    required
                    onChange={(e) =>
                      this.setState({ date_hired: e.target.value })
                    }
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>Monthly Basic</label>
                  <input
                    type="number"
                    step=".01"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({
                        basic_rate: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>With Regular Holiday Pay</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({ reg_holiday_pay: e.target.value });
                    }}
                  >
                    <option value="">Select...</option>
                    <option value="1">YES</option>
                    <option value="0">NO</option>
                  </select>
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>With Special Holiday Pay</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({ spl_holiday_pay: e.target.value });
                    }}
                  >
                    <option value="">Select...</option>
                    <option value="1">YES</option>
                    <option value="0">NO</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Tardiness On?</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({ tardiness_on: e.target.value });
                    }}
                  >
                    <option value="">Select...</option>
                    <option value="1">YES</option>
                    <option value="0">NO</option>
                  </select>
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Undertime On?</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({ undertime_on: e.target.value });
                    }}
                  >
                    <option value="">Select...</option>
                    <option value="1">YES</option>
                    <option value="0">NO</option>
                  </select>
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
